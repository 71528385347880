import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_configuracion_modelo_response } from 'src/model/Catalogos/Generales';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css'],
  providers: [ServiceGenerico]
})
export class ConfiguracionComponent implements OnInit {
  modalrefMsg: NgbModalRef;
  public operacionRespuesta: RespuestaGenerica;
  modelo_configuracion: ServiciosRutas;
  imprimirTicket: Lista_configuracion_modelo_response[];
  formGroupConfiguracion: any;

  constructor(
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
   }

  ngOnInit() {
    this.inicializarFormConfiguracion();
    this.obtenerConfiguracion();
  }

  inicializarFormConfiguracion() {
    this.formGroupConfiguracion = this.fb.group({
      p_imprimirTicket: [0]
        });
  }
  obtenerConfiguracion() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetConfiguracion?p_tbl_configuracion_id=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.imprimirTicket = tempdate.response as Lista_configuracion_modelo_response[];
            this.formGroupConfiguracion.get('p_imprimirTicket').setValue(this.imprimirTicket[0].imprimirTicket);
          } 
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  actualizarTicket() {
    let model = this.formGroupConfiguracion.value;
    this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Generales/PutConfiguracion")
    .subscribe(
      (tempdate) => {
        if (tempdate.response[0].success == 1) {
          this.openMensajes(tempdate.response[0].msg, false, '');
        } 
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async () => {
        this.openMensajes("No se pudo realizar la acción", true, '');
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
  }

  openMensajes(Mensaje: string, Error: boolean,titulo:string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, {ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Cofiguración';
  
    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
}
